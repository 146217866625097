<template>
  <div>
    <GmapMap
      ref="gmap"
      :center="mapCenter"
      :zoom="6"
      :options="mapOptions"
      style="width: 100%; height: 93vh"
    >
      <GmapCluster
        :options="clusterOptions"
        :zoom-on-click="true"
      >
        <!-- <GmapMarker
          v-for="(v, index) in vehicles"
          :key="index"
          :position="{ lat: v.lt, lng: v.ln }"
          @click="showInfo(v)"
        /> -->
        <gmap-custom-marker
          v-for="(v, index) in vehicles"
          :key="index"
          :marker="{ lat: v.lt, lng: v.ln }"
        >
          <div
            class="pin-icon-container"
            @click="showInfo(v)"
          >
            <div
              class="pin-title"
              :title="
                v.registration_no == 'N/A' ? v.chassis_no : v.registration_no
              "
            >
              {{
                v.registration_no == "N/A" ? v.chassis_no : v.registration_no
              }}
            </div>
            <div
              class="pin-circle"
              :class="v.st"
            >
              <Icons :name="`marker-${v.bt}`" />
            </div>
            <div class="pin-arrow" />
          </div>
        </gmap-custom-marker>
      </GmapCluster>
      <GmapInfoWindow
        :position="infoWindowPosition"
        :opened="infoWindowOpened"
        @closeclick="infoWindowOpened = false"
      >
        <div class="info-window">
          <div>
            <span>Tenant Name:</span>
            <span>{{ infoWindowContent?.tenant_name }}</span>
          </div>
          <div>
            <span>Latitude:</span> <span>{{ infoWindowContent.lt }}</span>
          </div>
          <div>
            <span>Longitude:</span> <span>{{ infoWindowContent.ln }}</span>
          </div>
          <div>
            <span>Customer Name:</span> <span>{{ infoWindowContent.dn }}</span>
          </div>
          <div>
            <span>Speed (KM/Hr):</span> <span>{{ infoWindowContent.spd }}</span>
          </div>
          <div v-if="infoWindowContent.vt === 'ev'">
            <span>SOC:</span> <span>{{ infoWindowContent.soc }}</span>
          </div>
          <div>
            <span>Ignition:</span> <span>{{ infoWindowContent.ign_s }}</span>
          </div>
          <div>
            <span>Status:</span> <span>{{ infoWindowContent.st }}</span>
          </div>
          <div>
            <span>Time:</span> <span>{{ infoWindowContent.sync_at }}</span>
          </div>
        </div>
      </GmapInfoWindow>
    </GmapMap>
  </div>
</template>

<script>
import Vue from "vue";
import VueMqtt from "vue-mqtt";
// import moment from "moment";
import GmapCustomMarker from "vue2-gmap-custom-marker";

Vue.use(VueMqtt, "wss://mqtt.flespi.io:443/", {
  clientId: "WebClient-" + parseInt(Math.random() * 100000),
  username: "H0i9Oy5cefXRGGdfNLZLpBt3s7h2h1aPRgOuDIuHLhniLonnXbWaCf3SbaJiZJVE",
});

export default {
  name: "GoogleLiveTracking",
  components: {
    GmapCustomMarker,
    Icons: () => import("@/components/base/icons.vue"),
  },
  props: {
    vehicles: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      mapCenter: { lat: 30.3753, lng: 69.3451 },
      mapOptions: {
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: window.google?.maps.ControlPosition.RIGHT_BOTTOM, // Place at right-bottom
        },
      },

      clusterOptions: {
        styles: [
          {
            url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
            height: 70,
            width: 70,
            textColor: "#ffffff",
            textSize: 18,
            backgroundColor: "rgba(0,255,0,0.7)", // Green background
          },
        ],
      },

      infoWindowOpened: false,
      infoWindowPosition: null,
      infoWindowContent: "",
    };
  },
  computed: {
    topics() {
      return this.vehicles.map(
        (r) => `flespi/message/gw/devices/${r.device_id}`
      );
    },
  },
  mounted() {
    if (this.vehicles.length) {
      this.mapCenter = { lat: this.vehicles[0].lt, lng: this.vehicles[0].ln };
    }

    this.initMap();

    this.subscribeToTopics();
  },
  beforeDestroy() {
    this.unsubscribeFromTopics();
  },
  methods: {
    initMap() {
      this.$refs.gmap.$mapPromise.then(() => {
        this.setMapBounds();
        if (window.google && window.google.maps) {
          this.mapOptions.fullscreenControlOptions = {
            position: window.google.maps.ControlPosition.BOTTOM_RIGHT, // Correct placement
          };
        }
      });
    },
    showInfo(v) {
      this.infoWindowPosition = { lat: v.lt, lng: v.ln };
      this.infoWindowContent = v;
      this.infoWindowOpened = true;
    },
    popupDetail(row) {
      return `<div>
            <div>Latitude: ${row.lt}</div>
            <div>Longitude: ${row.ln}</div>
            <div>Customer Name: ${row.dn}</div>
            <div>Speed (KM/Hr): ${row.spd}</div>
            ${row.vt === "ev" ? `<div> SOC: ${row.soc}</div>` : ""}
            <div>Ignition: ${row.ign_s}</div>
            <div>Status: ${row.st}</div>
            <div>Date Time: ${row.sync_at}</div>
          </div>`;
    },
    setMapBounds() {
      this.$refs.gmap.$mapPromise.then((map) => {
        // map is loaded now set  map bound
        if (!this.vehicles.length) return;

        const bounds = new window.google.maps.LatLngBounds();

        this.vehicles.forEach((v) => {
          bounds.extend(new window.google.maps.LatLng(v.lt, v.ln));
        });

        map.fitBounds(bounds);
        setTimeout(() => {
          let currentZoom = map.getZoom();
          map.setZoom(currentZoom - 1); // Zoom out slightly
        }, 500);
      });
    },

    subscribeToTopics() {
      this.topics.forEach((topic) => {
        this.$mqtt.subscribe(topic);
      });
    },
    unsubscribeFromTopics() {
      this.topics.forEach((topic) => {
        this.$mqtt.unsubscribe(topic);
      });
    },
    animateMarker(vehicle, newLat, newLng) {
      const duration = 1000;
      const start = performance.now();
      const startLat = vehicle.lt;
      const startLng = vehicle.ln;

      const step = (timestamp) => {
        const progress = Math.min((timestamp - start) / duration, 1);
        vehicle.lt = startLat + (newLat - startLat) * progress;
        vehicle.ln = startLng + (newLng - startLng) * progress;

        if (progress < 1) {
          requestAnimationFrame(step);
        }
      };
      requestAnimationFrame(step);
    },
    updateMarker(v) {
      const vehicle = this.vehicles.find(
        (vehicle) => vehicle.device_id === v.device_id
      );
      if (vehicle) {
        this.animateMarker(vehicle, v.lat, v.lng);
      }
    },
  },
  mqtt: {
    "flespi/message/gw/devices/#"(data) {
      const msg = JSON.parse(new TextDecoder().decode(new Uint8Array(data)));
      const params = {
        device_id: msg.ident,
        lat: msg.position_latitude,
        lng: msg.position_longitude,
      };
      this.updateMarker(params);
    },
  },
};
</script>

<style scoped>
#map {
  height: 100vh;
  width: 100%;
}
.pin-icon-container {
  position: relative;
  width: 40px;
  height: 60px;
}
.pin-title {
  position: absolute;
  text-align: center;
  top: -25px;
  left: -10px;
  background: #fff;
  border-radius: 2px;
  width: 65px;
  font-size: 12px;
  font-weight: 800;
  padding: 2px;
  /* text-wrap: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  /* justify-content: center; */
  white-space: nowrap;
  display: inline-block;
  box-shadow: 2px 2px 10px 2px gray;
}

.pin-circle {
  padding: 5px;
  width: 40px;
  height: 40px;
  /* background-color: #20a390; */
  background-color: #ffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: bold;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}
.pin-arrow {
  position: absolute;
  top: 40px;
  left: 15px;
  width: 10px;
  height: 20px;
  background-color: #ffffff;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

:deep .active svg path {
  fill: #23bdaa !important;
}

:deep .in_active svg path,
:deep .inactive svg path {
  fill: #ffae20 !important;
}

:deep .offline svg path {
  fill: #fa896b;
}
</style>
<style lang="sass" scoped>
:deep .cluster
  img
    display: none !important
  div
    background: #20a390  !important
    outline: 1rem double #20a390  !important
    border-radius: 100%  !important
    font-size: 14px  !important
    width: 35px  !important
    line-height: 35px  !important


.info-window
  div
    display: flex
    justify-content: space-between
    gap: 1rem
  span:last-child
    font-weight: 600
</style>
